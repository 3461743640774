import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from '../../images/logoEscacBlack.png'
import font from '../../fonts/CircularStd-Medium.otf'
import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Circular', src: font, fontStyle: 'normal', fontWeight: 'normal', });

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    etiqueta: {
        width: "50%",
        height: "120px",
        fontFamily: 'Circular',
        fontSize: 20,
        padding: 10,
        alignItems: "center",
        paddingTop: 20
    },
    titol: {
        width: "50%",
        height: "20px",
        fontFamily: 'Circular',
        alignItems: "center",
    },
});

const TableRow = ({ items }) => {
    const rows = items.map((item) => (
        <>
            <View style={styles.row} key={item.sr.toString()}>

                <Image src={logo} style={{marginRight: 55, width: "250px", paddingLeft: 10, paddingTop: 10}} />
                <Image src={logo} style={{width: "250px", paddingTop: 10}} />

            </View>
            <View style={styles.row} key={item.sr.toString()}>

                <Text style={styles.etiqueta}>{item.desc}</Text>
                <Text style={styles.etiqueta}>{item.xyz}</Text>

            </View>


        </>
    ));
    return <Fragment>{rows}</Fragment>;
	}


export default TableRow;
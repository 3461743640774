import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from '../../images/logoEscacBlack.png'
import font from '../../fonts/CircularStd-Medium.otf'
import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Circular', src: font, fontStyle: 'normal', fontWeight: 'normal', });

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    etiqueta: {
        width: "100%",
        fontFamily: 'Circular',
        fontSize: 20,
        padding: 10,
        alignItems: "center",
        paddingTop: 20,
        textAlign: 'center',
    },
    titol: {
        width: "50%",
        height: "20px",
        fontFamily: 'Circular',
        alignItems: "center",
    },
});

const TableRowBig = ({ items }) => {
    const rows = items.map((item) => (
        <>
            <View style={styles.row} key={item.sr.toString()}>
                <Image src={logo} style={{ marginRight: 55, width: "250px", paddingLeft: 10, paddingTop: 10, }} />
            </View>
            <View style={styles.row} key={item.sr.toString()}>
                <Text style={styles.etiqueta}>{item.desc}</Text>
            </View>
            <View style={styles.row} key={item.sr.toString()}>
                <Text style={{marginLeft: 70, fontSize: 14, fontFamily: 'Circular'}}>{item.des}</Text>
            </View>
        </>
    ));
    return <Fragment>{rows}</Fragment>;
}


export default TableRowBig;
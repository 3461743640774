import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import Login from './components/Login';
import Imprimir from './components/Imprimir';



function App() {

  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Imprimir />} />
            <Route element={
              <div><h1>Página no encontrado 404</h1></div>
            } />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
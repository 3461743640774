import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Table from './tableSmall/Table';
import Table2 from './tableSmall/Table2';
import Table3 from './tableSmall/Table3';
import Table4 from './tableSmall/Table4';
import Table5 from './tableSmall/Table5';
import TableBig from './tableBig/TableBig';
import TableBig2 from './tableBig/TableBig2';
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from 'react-bootstrap/ButtonGroup';



import Login from './Login';

const Imprimir = () => {

    const navigate = useNavigate();
    const [valido, setValido] = useState();
    const [tamano, setTamano] = useState();
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState();
    const [radioValueGrande, setRadioValueGrande] = useState();
    const [radioValuePequeno, setRadioValuePequeno] = useState();

    const [oneProduct, setOneProduct] = useState();
    const [allProducts, setAllProducts] = useState([]);
    const [encontrados, setEncontrados] = useState([]);
    const [busqueda, setBusqueda] = useState([]);
    const [nameQty, setNameQty] = useState("");
    const [descripcion, setDescripcion] = useState([]);
    const [show, setShow] = useState(false);
    const [accesToken, setAccesToken] = useState([]);
    const [datos, setDatos] = useState({
        items: [
            {
                sr: 2,
                desc: "logo",
                xyz: "logo",
                des: "descripcion"
            },
        ],
    });
    const handleClose = () => { setShow(false); };
    const handleShow = (name, descripcion) => {
        setShow(true);
        datos.items[0].desc = name;
        datos.items[0].xyz = name;
        datos.items[0].des = descripcion;
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/Login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    const radios = [
        { name: 'Grande', value: '1' },
        { name: 'Pequeña', value: '2' },
    ]
    const radiosGrande = [
        { name: '1', value: '1' },
        { name: '2', value: '2' },
    ]
    const radiosPequeno = [
        { name: '2', value: '1' },
        { name: '4', value: '2' },
        { name: '6', value: '3' },
        { name: '8', value: '4' },
        { name: '10', value: '5' },
    ]

    const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
        if (e.target.value !== "")
            setOneProduct([])
        if (e.target.value.length === 0)
            setEncontrados([])
    }

    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = allProducts.filter((elemento) => {
            if (elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()))
                return elemento;
        })
        setEncontrados(resultadosBusqueda);
        console.log(encontrados)
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                user.getIdToken().then((token) => {
                    console.log(token);
                    hacerSolicitudSegura(token);
                })
                setValido(true);
            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])


    function hacerSolicitudSegura(token) {
        const urlServidor = 'https://appsescac.com/getProducts'
        const headers = {
            //'Authorization': 'Bearer ' + token // Adjuntar el token al encabezado de autorización
        };
        axios.get(urlServidor, { headers })
            .then(response => {

                const res = response.data.data.value;
                const docs = []
                //console.log(res)

                res.forEach((i) => {
                    if (i.categ_id[1] !== "CONSUMIBLE") {
                        //console.log(i.categ_id[1].substr(4))
                        docs.push({ name: i.name, categ: i.categ_id[1].substr(4), descripcion: i.x_studio_descripcion })
                    }
                })
                setAllProducts(docs);

            })
            .catch(error => {
                console.log(error);
            });

    }

    if (valido) return (
        <>

            <Container>
                <div class="d-flex justify-content-end">

                    <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleLogout}>
                        Salir
                    </button>
                </div>
                <div className="mt-0 mt-lg-4 p-3 p-lg-0 p-md-0 p-sm-0 mt-sm-2 border-bottom border-2 border-dark mb-4">
                    <h2 className="m-0 fw-bold ">Imprimir etiquetas</h2>
                </div>

                <div>
                    <div className="conteinerInput">
                        <input
                            className="form-control border border-dark"
                            valor={busqueda}
                            placeholder="Escribe por Nombre de producto aquí..."
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <table className="table">
                            <thead>
                            </thead>
                            <tbody>
                                {
                                    encontrados.map((encontrado) =>
                                        <tr>
                                            <td>
                                                <Button className="btn btn-light"
                                                    onClick={() => { setNameQty(encontrado.name); setDescripcion(encontrado.descripcion); handleShow(encontrado.name, encontrado.descripcion); }}
                                                >
                                                    {encontrado.name}
                                                </Button>
                                            </td>
                                            <td>

                                            </td>
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{nameQty}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Tamaño de etiqueta</p>
                                <ButtonGroup>
                                    {radios.map((radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            id={`radio-${idx}`}
                                            type="radio"
                                            variant={idx % 2 ? 'outline-dark' : 'outline-dark'}
                                            name="radio"
                                            value={radio.value}
                                            checked={radioValue === radio.value}
                                            onChange={(e) => { setRadioValue(e.currentTarget.value); setRadioValueGrande('0'); setRadioValuePequeno('0'); }}
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>

                                {radioValue === '1' &&
                                    <>
                                        <p className="mt-4 mb-1">Cantidad por página</p>
                                        <ButtonGroup>
                                            {radiosGrande.map((radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`radioGrande-${idx}`}
                                                    type="radio"
                                                    variant={idx % 2 ? 'outline-dark' : 'outline-dark'}
                                                    name="radioGrande"
                                                    value={radio.value}
                                                    checked={radioValueGrande === radio.value}
                                                    onChange={(e) => setRadioValueGrande(e.currentTarget.value) }
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </>
                                }

                                {radioValue === '2' &&
                                    <>
                                        <p className="mt-4 mb-1">Cantidad</p>
                                        <ButtonGroup>
                                            {radiosPequeno.map((radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`radioPequeno-${idx}`}
                                                    type="radio"
                                                    variant={idx % 2 ? 'outline-dark' : 'outline-dark'}
                                                    name="radiopequeno"
                                                    value={radio.value}
                                                    checked={radioValuePequeno === radio.value}
                                                    onChange={(e) => setRadioValuePequeno(e.currentTarget.value)}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </>
                                }

                            </Modal.Body>

                           
                                {radioValueGrande === '1' &&
                                    <PDFDownloadLink document={<TableBig data={datos} />} fileName="ETIQUETA_GRANDE_1.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                                {radioValueGrande === '2' &&
                                    <PDFDownloadLink document={<TableBig2 data={datos} />} fileName="ETIQUETA_GRANDE_2.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                                {radioValuePequeno === '1' &&
                                    <PDFDownloadLink document={<Table data={datos} />} fileName="ETIQUETA_SMALL_2.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                                {radioValuePequeno === '2' &&
                                    <PDFDownloadLink document={<Table2 data={datos} />} fileName="ETIQUETA_SMALL_4.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                                {radioValuePequeno === '3' &&
                                    <PDFDownloadLink document={<Table3 data={datos} />} fileName="ETIQUETA_SMALL_6.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                                {radioValuePequeno === '4' &&
                                    <PDFDownloadLink document={<Table4 data={datos} />} fileName="ETIQUETA_SMALL_8.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                                {radioValuePequeno === '5' &&
                                    <PDFDownloadLink document={<Table5 data={datos} />} fileName="ETIQUETA_SMALL_10.pdf">
                                        <Button className="m-2 btn btn-dark"> Descargar </Button>
                                    </PDFDownloadLink>
                                }
                         
                        </Modal>
                    </div>
                </div>
            </Container>



        </>
    )

    return (

        <Login />

    )
}

export default Imprimir
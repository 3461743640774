import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { auth } from '../firebaseConfig/firebase';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import logo from '../images/logo_escac_black2.png';


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/")

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    const handleMicrosoftLogin = (e) => {
        e.preventDefault();
        //setLoader(prevState => ({ ...prevState, microsoftLoading: true }));
        const provider = new OAuthProvider('microsoft.com').setCustomParameters({
            tenant: 'escaces.onmicrosoft.com'
        });

        signInWithPopup(auth, provider)
            .then(data => {
                //console.log(data);


                navigate("/")
                //setLoader(prevState => ({ ...prevState, microsoftLoading: false }));
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });
    }



    return (
        <>
            <main className="bg-dark">
                <section>
                    <Container>
                        <Row>

                            <Col lg={4} className="bg-white min-vh-100" >
                                <Row>
                                    <Col className="pt-5 ps-5 pe-5">
                                        <Row><div className="align-self-center ps-2 pe-2 mb-5"><img src={logo} alt="ESCAC" width="400" className="img-fluid" /></div></Row>
                                        <Row className="mt-4"><h4 className="ps-0">Identifícate</h4></Row>
                                        <Row className=" me-5">
                                            <button className="mt-2" style={{ width: '70px' }} onClick={handleMicrosoftLogin}>
                                                Entra
                                            </button>
                                        </Row>
                                    </Col>

                                </Row>

                            </Col>
                            <Col className=""></Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    )
}

export default Login
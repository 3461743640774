import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableRowBig from "./TableRowBig";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    height: "400px"
  },
});

const ItemsTableBig = ({ data }) => (
  <View style={styles.tableContainer}>
    {/*<TableHeader />*/}
    <TableRowBig items={data.items} />

    {/*<TableFooter items={data.items} />*/}
  </View>
);

export default ItemsTableBig;